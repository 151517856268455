const shelf = [
  { id: 1, name: "Biography - জীবনী", type: "biography" },
  { id: 2, name: "Science Fiction - সায়েন্স ফিকশন", type: "scienceFiction" },
  { id: 3, name: "Child History - শিশু সাহিত্য", type: "childHistory" },
  { id: 4, name: "Paragraph - রচনা", type: "paragraph" },
  { id: 5, name: "Fiction - ফিকশন", type: "fiction" },
  { id: 6, name: "Translated - অনুবাদ", type: "translated" },
  { id: 7, name: "Poem - কবিতা", type: "poem" },
  { id: 8, name: "Novel - উপন্যাস", type: "novel" },
  { id: 9, name: "Religion - ধর্মীয়", type: "religion" },
  { id: 10, name: "History - ইতিহাস", type: "history" },
];

export default shelf;
