const collections = [
  {
    id: 1,
    name: "এই সপ্তাহের বই",
    type: "week",
  },
  {
    id: 2,
    name: "বেস্টসেলার",
    type: "bestSeller",
  },
  {
    id: 3,
    name: "জনপ্রিয় বই",
    type: "popular",
  },
  {
    id: 4,
    name: "নির্বাচিত বই",
    type: "selective",
  },
  {
    id: 5,
    name: "পছন্দের বই",
    type: "favourite",
  },
  {
    id: 6,
    name: "নতুন প্রকাশিত",
    type: "newPublished",
  },
  {
    id: 7,
    name: "শীগ্রই আসছে",
    type: "upcoming",
  },
];

export default collections;
